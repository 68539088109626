@import url('https://fonts.googleapis.com/css?family=inter:regular,bold,italic&subset=latin,latin-ext');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Lexend', sans-serif;
}

.richText p {
    margin-bottom: 1em;
    line-height: 1.6;
    font-size: 16px;
}

/* Stili per titoli di vario livello */
.richText h1, .richText h2, .richText h3, .richText h4, .richText h5, .richText h6 {
    margin-top: 35px;
    font-weight: bold;
}

.richText h1 {
    font-size: 2em;
}

.richText h2 {
    font-size: 1.8em;
}

.richText h3 {
    font-size: 1.6em;
}

.richText a {
    color: #007bff;
    text-decoration: none;
}

.richText a:hover {
    text-decoration: underline;
}

/* Stili per liste */
.richText ul, .richText ol {
    margin-bottom: 0.5em;
    padding-left: 0.5em;
    list-style-type: circle;

}

/* Stili per elementi in grassetto e corsivo */
.richText strong, .richText b {
    font-weight: bold;
}

.richText em, .richText i {
    font-style: italic;
}

.swiper-pagination-bullet {
    background-color: white !important;
    border-color: white !important;
  }

@media screen and (min-width: 1024px) {
    .smoke-effect-blue::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40%;
        background: linear-gradient(to left, rgba(0, 159, 227, 1.0), rgba(0, 159, 227, 0));
        z-index: 30;
    }


    .smoke-effect-white::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30%;
        background: linear-gradient(to left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
        z-index: 30;
    }
}


